import { debounce } from '@/utils/utils.js';
import { voucherInit, getMemberCardQrCode, getEPSInfo } from '@/api/index.js';
import localStore from 'storejs'; // localStorage的二次封装(可直接存储与获取对象)

export default {
	data() {
		return {
			// bgSrc: require('@/assets/img/djcg.jpg'),
			qrCodeText: "", // 二维码内容
      qrCodelogo: require('@/assets/img/qrlogo.jpg'), // 二维码logo图片路径
      campLogo: require('@/assets/img/camp.png'), // 露营展二维码logo图片路径
      groupImg: require('@/assets/img/group.png'),
      serviceImg: require('@/assets/img/customer-service.png'),
			timer: 0, // 定时器: 初始值不能为null, 否则在销毁钩子内无法识别this.timer
      payState: false, // 门票是否已过期
      clientTypeName: '', // 报名类型
      ticketType: '', // 门票类型
      epsInfo: {
        // acid_color: "绿码", // 绿码，红码，黄码
        // acid_hour: "24", // 24，48,72,1000（不显示数字了，只显示阴性2字
        // acid_info: "",
        // acid_result: "阴性", //  阴性，阳性
        // acid_time: "2020-07-17 06:28:53", // 核酸时间
        // idcardExist: "1", // 1=身份证有效，0=无身份证
      },
      customerName: '',
      isCamp: false, // 是否露营展
      isCampNormal: false, // 是否露营展
      isVip: false, // 露营展VIP、海外VIP(H5)
      isShowGroup: true, // 是否显示团体登记栏
  	}
  },
	created() {
    let flowId = localStore('flowId');
    this.isCamp = flowId == 902 || flowId == 8102;
    this.isCampNormal = flowId == 8102;
    this.isVip = flowId == 902 || flowId == 6101;
    
		this.getMemberData();
    // this.getEPSInfo();
    
		this.refreshQrCode();
	},
	methods: {
    toPurchase() {
      this.payState = false;
      this.$router.replace('/pay_en')
    },
		getMemberData() {
      let params = {
        browserType: 2, // 1-小程序, 2-H5
      }
			voucherInit(params).then(res => {
        console.log(res);
        let {css, cssEn, data = {}, client_type_name} = res.data;
        css && localStore.set('css', css);
        cssEn && localStore.set('cssEn', cssEn);
        
        let {customerName = '', pay_ticket_type = 0, pay_state} = data.voucher;
        this.ticketType = ['', '1-day ticket', '2-day ticket', '4-day ticket'][pay_ticket_type]; // 门票类型
        this.payState = pay_state == 2, // 门票状态 - 门票是否过期: 2-过期
        this.customerName = customerName;
        this.clientTypeName = client_type_name;
        setTimeout(()=>{
          // 门票过期后停止刷新二维码
          this.payState && this.timer && clearInterval(this.timer)
        }, 1000)
			})
		},
		// 获取二维码内容
		getQrCode() {
			getMemberCardQrCode(this.unionid).then(res=>{
				if (res.code == 200) {
					this.qrCodeText = res.data
				}
			})
		},
		// 设置定时器 
		setTimer() {
			// 清理定时器
			this.timer && clearInterval(this.timer);
	
			this.getQrCode();

			let time = 10; // 定时器时间间隔
			this.timer = setInterval(() => {
				time--; // 倒计时: 时间间隔按秒钟递减
				if (time === 0) {
					clearInterval(this.timer); // 清理定时器
					this.timer = 0; // 清理定时器后设置默认值，用于他处判断
					this.refreshQrCode();
					return;
				}
			}, 1000);
		},
		// 刷新二维码, 节流
		refreshQrCode: debounce('setTimer', 500),
    // 获取防疫检测结果
    getEPSInfo() {
    	getEPSInfo(this.unionid).then(res=>{
    		if (res.code == 200) {
    			this.epsInfo = res.data
    		}
    	})
    },
	},
	destroyed() {
		// this.timer && clearInterval(this.timer);
		if(this.timer) {
			clearInterval(this.timer);
			console.log('memberCode 定时器: ',this.timer);
		}
	}
}
