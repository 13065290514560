<template>
	<!-- style.mode: 0-通用(普通), 1-VIP, 2-通用VIP -->
	<!-- <div class="page" :style="{backgroundColor: style.mainColor}"> -->
	<div class="page" :style="{'background-image': `url(${style.memberBgImg1 || bgSrc})`, 'background-color': `${style.bgColor || '#fff'}`}">
		<div class="top-box">
			<div class="logo">
				<img :src="style.logo || logoSrc" alt="" class="img">
			</div>
			<div class="title">普通观众入场凭证</div>
			<div class="name-box">
				<span v-if="data.trade" style="margin-right: 20px;">{{data.trade}}</span>
				<span>{{data.customerName}}</span>
			</div>
		</div>
		<div class="qrcode-container radius" @click="refreshQrCode">
			<vue-qr :text="qrCode.text" :correctLevel="3" :margin="15" colorDark="#000" colorLight="#fff" :dotScale="1"
			 :logoSrc="qrCode.logo" :logoScale="0.3" :size="300" style="width: 100%;">
			</vue-qr>
		</div>
		<div class="tips-content">
			<span>入场时需刷上方专属二维码绑定人脸</span>
			<span>一人一码，截图或转发无效</span>
			<span>请同时携带有效身份证件以备查验</span>
		</div>
		
		<div class="footer">
			<bottom :mode="style.mode" :level="style.level" color="#ffffff"></bottom>
		</div>
		
  </div>
</template>

<script>

	import vueQr from "vue-qr";
	import commonMixin from '@/view/process/mixin/common.js'
	import memberCodeMixin from '@/view/process/mixin/memberCode.js'

	export default {
		mixins: [commonMixin, memberCodeMixin],
		components: {
			vueQr
		},
		data() {
			return {
				
			}
		},
		created() {
			this.setLanguge('zh'); // 设置语言环境
		},
		methods: {
			
		}
	}
</script>

<style lang="less" scoped>
	// @color: #9F9D9D;
	// @color: #6D6D6D;
	
	.font14 {
		font-size: 13px;
		font-weight: bold;
	}
	.top-box {
		width: 320px;
		margin: 0 auto 4vh;
		color: #fff;
		font-family: Source Han Sans CN;
	}
	.logo {
		margin: 4vh auto 2vh;
		
		.img {
			width: 150px;
		}
	}
	
	.title {
		font-size: 20px;
		font-weight: 500;
		margin-bottom: 6px;
		letter-spacing: 1px;
	}
	.name-box {
		font-size: 18px;
		margin-bottom: 6px;
		font-weight: normal;
	}
		
	.qrcode-container {
		background-color: #fff;
		margin: 0 auto 2vh;
		// padding: 10px;
		width: 240px;
		height: 240px;
	}
	
	.tips-content {
		font-size: 12px;
		color: #fff;
		font-family: 'DIN-Regular';
		
		span {
			display: block;
			text-align: center;
			line-height: 22px;
			// margin-bottom: 2vh;
		}
	}

</style>
